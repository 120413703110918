import React from 'react'
import { useStaticQuery, graphql, Link } from 'gatsby'
import Helmet from 'react-helmet'
import Layout from '../../components/Layout'
import ServiceFooter from '../../components/ServiceFooter'

const Camera = () => {
  const {
    site: {
      siteMetadata: { title },
    },
  } = useStaticQuery(graphql`
    {
      site {
        siteMetadata {
          title
        }
      }
    }
  `)

  return (
    <Layout>
      <Helmet>
        <html className="temp" lang="ja" />
        <title>{`防犯カメラ | ${title}`}</title>
        <meta
          name="description"
          content="2.0メガピクセルカメラ（200万画素）を搭載したフルハイビジョン高画質を最大約２週間分録画可能。0.005ルクスの超低照度カメラが夜間・屋内の映像を捉え、防犯対策など様々な用途で活躍します。"
        />
      </Helmet>

      <div className="common-header clearfix">
        <p className="category pc-only">製品・サービス</p>
        <h1>防犯カメラ</h1>
        <p className="category sp-only">製品・サービス</p>

        <div className="breadcrumb">
          <li>
            <Link to={`/`}>トップページ</Link>
          </li>
          /
          <li>
            <Link to={`/service`}>製品・サービス</Link>
          </li>
          /<li>防犯カメラ</li>
        </div>
      </div>

      <div className="main-wrapper camera">
        <div className="row">
          <div className="container empty"></div>
        </div>

        <div className="row">
          <div className="h-wrapper">
            <h2>コストパフォーマンスで選ぶ防犯カメラシステム</h2>
            <div className="common-btn pc-only">
              <a href="/assets/files/whitepaper/itc-camera.pdf" target="_blank">
                カタログダウンロード
              </a>
            </div>
          </div>
        </div>

        <div className="row">
          <div className="container top">
            <h4>
              2.0メガピクセルカメラ（200万画素）を搭載したフルハイビジョン高画質を最大約２週間分録画可能。0.005ルクスの超低照度カメラが夜間・屋内の映像を捉え、防犯対策など様々な用途で活躍します。
            </h4>

            <p>
              最大12台のカメラを接続することができ、インターネット接続があれば遠隔管理システムi-BARMSを使用してライブ映像や過去の映像を確認できます。
            </p>

            <picture>
              <source
                type="image/webp"
                srcset="/assets/images/common/service-camera-01.webp"
              />
              <img
                className="camera-01"
                src="/assets/images/common/service-camera-01.png"
                height="538"
                alt=""
              />
            </picture>

            <div className="separate-area">
              <div className="box">
                <picture>
                  <source
                    type="image/webp"
                    srcset="/assets/images/common/service-camera-02.webp"
                  />
                  <img
                    className="camera-02"
                    src="/assets/images/common/service-camera-02.jpg"
                    height="494"
                    alt=""
                  />
                </picture>
                <p>昼間画像</p>
              </div>
              <div className="box">
                <picture>
                  <source
                    type="image/webp"
                    srcset="/assets/images/common/service-camera-03.webp"
                  />
                  <img
                    className="camera-03"
                    src="/assets/images/common/service-camera-03.jpg"
                    height="494"
                    alt=""
                  />
                </picture>
                <p>夜間画像</p>
              </div>
            </div>
          </div>
        </div>

        <div className="row">
          <div className="h-wrapper">
            <h3>ITC-C20HA 標準仕様</h3>
          </div>
        </div>

        <div className="row">
          <div className="container spec">
            <table>
              <tbody>
                <tr>
                  <th>型番</th>
                  <td>ITC-C20HA</td>
                </tr>
                <tr>
                  <th>レンズ</th>
                  <td>F1.6/焦点距離2.8mm 水平視野107°</td>
                </tr>
                <tr>
                  <th>イメージセンサー</th>
                  <td>1 / 2.8” CMOS 最大画素数 1920×1080</td>
                </tr>
                <tr>
                  <th>録画時間</th>
                  <td>2週間（ストレージは別ユニット）</td>
                </tr>
                <tr>
                  <th>最小照度</th>
                  <td>0.005 lux(カラー時) / ０ lux(IR撮影時)</td>
                </tr>
                <tr>
                  <th>IR照射距離</th>
                  <td>最大40m</td>
                </tr>
                <tr>
                  <th>サイズ／質量</th>
                  <td>φ74.4×179.2mm / 500g</td>
                </tr>
                <tr>
                  <th>IP規格</th>
                  <td>IP67</td>
                </tr>
                <tr>
                  <th>電源</th>
                  <td>DC12V　カメラ電源BOX供給</td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>

        <ServiceFooter />
      </div>
    </Layout>
  )
}

export default Camera
